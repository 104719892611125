import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import Logo from "../assets/images/logo.png";
import {
  Bars3Icon,
  XMarkIcon,
  TrashIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import PageFooter from "./pageFooter";
import { useNavigate } from "react-router-dom";

const Cancel = () => {
  const navigate = useNavigate();
  return (
    <>
      <Disclosure
        as="nav"
        className="bg-white shadow sticky top-0 z-30 w-full px-1 sm:px-4"
      >
        {({ open }) => (
          <>
            <div className="">
              <div className="flex h-20 justify-between">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center">
                    <a href="/">
                      <img
                        className="block h-12 w-auto lg:hidden"
                        src={Logo}
                        alt="Your Company"
                      />
                    </a>
                    <a href="/">
                      <img
                        className="hidden h-12 w-auto lg:block"
                        src={Logo}
                        alt="Your Company"
                      />
                    </a>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8 e">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <a
                      onClick={() => navigate("/buy-examsm")}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      About Exam
                    </a>

                    <a
                      onClick={() => navigate("/terms-and-conditions")}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      Terms and Conditions
                    </a>
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center"></div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon
                        className="block h-6 w-6 text-black"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pb-3 pt-2">
                <Disclosure.Button
                  as="a"
                  href="/buy-examsm"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                >
                  About Exam
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/terms-and-conditions"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 "
                >
                  Terms and Conditions
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className=" w-full  md:h-full sm:h-full bg-transparent">
        <div className="flex justify-center md:my-12">
          <div className="inline-block md:w-5/12 p-8 text-justify font-[Verdana]">
            <div className="flex justify-center  mx-4">
              <XCircleIcon className="w-14 text-red-500" />
            </div>
            <h1 className="font-bold text-center text-2xl my-4">
              Payment Failed
            </h1>
            <p className="my-3">
              We apologize, but we were unable to process your payment. It seems
              that there was an error with your payment method.
            </p>
            <p className="my-3">
              Please check that your payment information is correct and
              up-to-date, and try again. Alternatively, you may try using a
              different payment method.
            </p>
            <p className="my-3">
              If you continue to experience issues, please don't hesitate to
              contact our{" "}
              <a
                href="mailto:admin@nrichlearning.com.au"
                className="text-indigo-800 font-semibold hover:text-indigo-800"
              >
                customer support team
              </a>{" "}
              for assistance.
            </p>
            <p className="mb-2">Thank you for your understanding.</p>
          </div>
        </div>
      </div>

      <PageFooter />
    </>
  );
};
export default Cancel;
