import React from "react";
import {
  FaFacebook,
  FaWhatsapp,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { PhoneIcon, EnvelopeIcon, DocumentCheckIcon } from "@heroicons/react/24/outline";

const PageFooter = () => {
  return (
    <>
      <div className="text-white bg-indigo-600">
        <div className="container mx-auto flex flex-wrap justify-between p-4">
          <div className="w-full md:w-1/2 mb-6 sm:mb-0">
            <h2 className="font-bold text-lg mb-2">Contact for any Info</h2>
            <p>Contact us to find out the details about the services we offer.</p>
            <a href="tel:+610434678639" className="flex inline-block mt-2">
              <PhoneIcon className="h-5 w-5 mt-1 mr-2" title="Phone call" />
              <span className="font-white font-bold text-xl content-center">
                0434 678 639
              </span>
            </a>
          </div>
          <div className="w-full md:w-1/2 mb-6 sm:mb-0">
            <h2 className="font-bold text-lg mb-2">Stay Updated on Social</h2>
            <p>You can see important updates about us on social media apps like Facebook, WhatsApp, LinkedIn or our YouTube channel.</p>
            <div className="flex py-4 inline-block">
              <a href={"https://www.facebook.com/nrichlearning"} className="mr-3">
                <FaFacebook
                  style={{ backgroundColor: "#3b5998" }}
                  title="Facebook"
                  className="h-8 w-8 p-2"
                />
              </a>
              <a className="mr-3" href="#">
                <FaWhatsapp
                  title="Whatsapp"
                  style={{ backgroundColor: "#6FC191" }}
                  className="h-8 w-8 p-2"
                />
              </a>
              <a
                className="mr-3"
                href="https://www.linkedin.com/company/nrich-learning/"
              >
                <FaLinkedin
                  title="LinkedIn"
                  style={{ backgroundColor: "#0077b5" }}
                  className="h-8 w-8 p-2"
                />
              </a>
              <a
                className="mr-3"
                href="https://www.youtube.com/channel/UClGxU6olYpbFsPceUl-JXdw"
              >
                <FaYoutube
                  title="Youtube"
                  style={{ backgroundColor: "#e16c6c" }}
                  className="h-8 w-8 p-2"
                />
              </a>
            </div>
          </div>
        </div>
        <hr className="text-gray-50 bg-gray-50"/>
      </div>
      <div className="text-white bg-indigo-900">
        <div className="container mx-auto flex flex-wrap justify-between items-center px-4">
          <div className="w-full md:w-1/2 mb-6 sm:mb-0 py-2">
            <span> © 2022 NRICH LEARNING. ALL RIGHTS RESERVED.</span>
          </div>
          <div className="w-full md:w-1/2 mb-6 sm:mb-0 flex flex-col lg:flex-row justify-between">
            <div className="py-2 flex">
              <EnvelopeIcon className="w-4 mt-1 mr-1" />
              <a href="mailto:admin@nrichlearning.com.au">admin@nrichlearning.com.au</a>
            </div>
            <div className='flex py-2'>
              <DocumentCheckIcon className="w-4 mt-1 mr-1" />
              <a href="https://www.nrichlearning.com.au/terms-and-conditions/">Terms & conditions</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageFooter;
