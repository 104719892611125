import * as Yup from "yup";
const moment = require("moment");

export const openEnrollment = Yup.object({
  parentFirstName: Yup.string()
    .required("Please enter parent's First Name")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  parentLastName: Yup.string()
    .required("Please enter parent's Last Name")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  parentEmail: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter parent email address"),
  parentMobileNo: Yup.string().required("Please enter parent's mobile phone"),
  studentsFirstName: Yup.string()
    .required("Please enter the students first name")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  studentsLastName: Yup.string()
    .required("Please enter the students last name")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  terms: Yup.boolean().oneOf(
    [true],
    "Please accept the terms and conditions"
  ),
});
