// import "./index.css";
import React, { useState } from "react";
import {
  CalculatorIcon,
  ChatBubbleLeftRightIcon,
  ChevronRightIcon,
  ClipboardDocumentListIcon,
  NewspaperIcon,
  PencilSquareIcon,
  VariableIcon,
} from "@heroicons/react/24/outline";
import s1 from "../assets/images/s1.png";
import s2 from "../assets/images/s2.png";
import s3 from "../assets/images/s3.png";
import s4 from "../assets/images/s4.png";
import s5 from "../assets/images/s5.png";
import s6 from "../assets/images/s6.png";
import s7 from "../assets/images/s7.jpeg";
import s9 from "../assets/images/s9.jpeg";
import s10 from "../assets/images/s10.jpeg";
import PageFooter from "./pageFooter";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
// import Logon from "/../src/assets/images/logo.png";
import Logo from "../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";

function Aboutus() {
  const navigate = useNavigate();
  return (
    <>
      <Disclosure
        as="nav"
        className="bg-white shadow sticky top-0 z-30 w-full px-1 sm:px-4"
      >
        {({ open }) => (
          <>
            <div className="">
              <div className="flex h-20 justify-between">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center">
                    <a href="https://www.nrichlearning.com.au/">
                      <img
                        className="block h-12 w-56 lg:hidden"
                        src={Logo}
                        alt="Your Company"
                      />
                    </a>
                    <a href="https://www.nrichlearning.com.au/">
                      <img
                        className="hidden h-12 w-56 lg:block"
                        src={Logo}
                        alt="Your Company"
                      />
                    </a>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8 e">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <a
                      onClick={() => navigate("/")}
                      className="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-indigo-500 border-indigo-500 hover:border-gray-300 hover:text-indigo-700 cursor-pointer"
                    >
                      About 
                    </a>
                    <a
                      onClick={() => navigate("/buy-exams")}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium hover:border-indigo-300  hover:text-gray-700 cursor-pointer"
                    >
                      Buy Exam
                    </a>

                    <a
                      onClick={() => navigate("/terms-and-conditions")}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium hover:border-indigo-300  hover:text-gray-700 cursor-pointer"

                    >
                      Terms and Conditions
                    </a>
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center"></div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon
                        className="block h-6 w-6 text-black"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pb-3 pt-2">
              <Disclosure.Button
                  as="a"
                  href="/buy-exams"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-indigo-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 "
                >
                  About
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/buy-exams"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 "
                >
                  Buy Exam
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/terms-and-conditions"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 "
                >
                  Terms and Conditions
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="mx-4 mt-10">
        <div className="mt-10">
          <h1 className="text-center text-2xl md:text-4xl font-bold mb-10 mt-5">
            About the exams
          </h1>
          <p className="md:text-2xl text-xl px-4 md:px-8 text-center">
            Selective schools cater to high-performing students who require an
            accelerated curriculum to meet or exceed academic achievement. The
            Victorian Selected Schools Exam is a competitive selection process
            for admission to the four selected schools in Victoria. Melbourne
            High School, The Mac Robertson Girls' High School, Nossal High
            School, Suzanne Corey High School. The entrance exam is a written
            assessment designed to test ability rather than achievement and will
            include a focus on higher-order thinking skills.
            <br></br>
            <br></br>
            The Selective High Schools enrol students based on one of three
            categories, merit, fairness and consideration, and the Principal's
            discretion. To increase the student’s chances of success, it is
            essential for the student to learn concepts, become familiar with
            the different types of questions that may arise, and practice good
            testing techniques. Many schools do not have the resources to teach
            students how to tackle complex reasoning, math, and reading problems
            in a limited amount of time. That is why we have developed programs
            and support materials designed to help our students prepare for
            their Selective School Exams.
          </p>
        </div>
        <div className="py-8 md:py-16">
          <h3 className="uppercase text-center text-gray-600 font-bold md:font-semibold md:mt-10  mt-5 ">
            Types Of Tests
          </h3>
          <h1 className="text-2xl md:text-4xl font-bold text-center mt-4">
            The Selective School Exam consists of the following tests
          </h1>
          <div className=" mt-12 md:mt-24 lg:flex md:flex lg:wrap lg:justify-between items-baseline">
            <div className="p-3 md:w-56 text-center">
              <div className="w-16 rounded-full p-4 bg-blue-100 mx-auto">
                <ChatBubbleLeftRightIcon className="w-8" />
                {/* <img  src={s11} className="w-16"/> */}
              </div>
              <h3 className="font-semibold text-2xl mt-3">Verbal</h3>
              <p className="text-2xl  mt-2 text-gray-500">Reasoning</p>
            </div>
            <div className="p-3 md:w-56 text-center">
              <div className="w-16 rounded-full p-4 bg-blue-100 mx-auto">
                <VariableIcon className="w-8" />
              </div>
              <h3 className="font-semibold text-2xl mt-3">Numerical</h3>
              <p className="text-2xl  mt-2 text-gray-500">Reasoning</p>
            </div>
            <div className="p-3 md:w-56 text-center">
              <div className="w-16 rounded-full p-4 bg-blue-100 mx-auto">
                <ClipboardDocumentListIcon className="w-8" />
              </div>
              <h3 className="font-semibold text-2xl mt-3">
                Reading Comprehension
              </h3>
              <p className="text-2xl  mt-2 text-gray-500">General Ability</p>
            </div>
            <div className="p-3 md:w-56 text-center">
              <div className="w-16 rounded-full p-4 bg-blue-100 mx-auto">
                <CalculatorIcon className="w-8" />
              </div>
              <h3 className="font-semibold text-2xl mt-3">Mathematics</h3>
              <p className="text-2xl  mt-2 text-gray-500">General Ability</p>
            </div>
            <div className="p-3 md:w-56 text-center">
              <div className="w-16 rounded-full p-4 bg-blue-100 mx-auto">
                <NewspaperIcon className="w-8" />
              </div>
              <h3 className="font-semibold text-2xl mt-3">
                Persuasive or Narrative essay writing
              </h3>
              <p className="text-2xl  mt-2 text-gray-500">Writing Task</p>
            </div>
          </div>
          <div className="text-center mt-10">
            <a href="/buy-exams">
              <button
                type="button"
                className="transition-scale duration-500 hover:scale-90 text-center rounded-md bg-indigo-800 px-16 py-4 text-xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Buy Now
              </button>
            </a>
          </div>
        </div>
        <div className="">
          <h3 className="uppercase text-center text-gray-600 font-semibold mt-10 ">
            The Selection criteria
          </h3>
          <h1
            className="text-2xl lg:text-4xl font-bold text-center mt-4 lg:w-8/12 mx-auto md:leading-10 "

          >
            There are three major criteria based on which a student is offered a
            place in the Selective schools
          </h1>
          <div className=" mt-12 md:mt-24 flex flex-col md:flex-row justify-between items-center">
            <div className="p-3 md:w-4/12  text-center">
              <div className=" rounded-full  mx-auto">
                <h1 className="text-3xl md:text-6xl text-indigo-800 text-center font-semibold">
                  85%
                </h1>
              </div>
              <h3 className="md:font-semibold font-bold text-xl md:text-3xl mt-3 mb-5">Merit</h3>
              <a
                href="https://www2.education.vic.gov.au/pal/specialism-schools-and-programs/policy"
                className="md:text-xl text-lg  mt-2 text-gray-600 underline hover:text-blue-900 cursor-pointer  decoration-gray-400"
              >
                Selection criteria
              </a>
            </div>

            <div className="p-3 md:w-4/12 text-center">
              <div className=" rounded-full  mx-auto">
                <h1 className="text-3xl md:text-6xl text-indigo-800 text-center font-semibold">
                  10%
                </h1>
              </div>
              <h3 className="md:font-semibold font-bold text-xl md:text-3xl mt-3 mb-5">
                Equal Opportunity
              </h3>
              <a
                href="https://www2.education.vic.gov.au/pal/specialism-schools-and-programs/policy"
                className="md:text-xl text-lg  mt-2 text-gray-600 underline hover:text-blue-900 cursor-pointer  decoration-gray-400"
              >
                Selection criteria
              </a>
            </div>

            <div className="p-3 md:w-4/12 text-center">
              <div className=" rounded-full  mx-auto">
                <h1 className="text-3xl md:text-6xl text-indigo-800 text-center font-semibold">
                  5%
                </h1>
              </div>
              <h3 className="md:font-semibold font-bold text-xl md:text-3xl mt-3 mb-5">
                Principal’s Discretion
              </h3>
              <a
                href="https://www2.education.vic.gov.au/pal/specialism-schools-and-programs/policy"
                className="md:text-xl text-lg  mt-2 text-gray-600 underline hover:text-blue-900 cursor-pointer decoration-gray-400 "
              >
                Selection criteria
              </a>
            </div>
          </div>
          <p className="text-lg md:text-2xl mt-12 text-center">
            The student/parent needs to familiarise with the selection criteria
            to avoid disappointment. The selection becomes more competitive when
            many high-achieving students apply from a single school. The 4% rule
            applies in such cases: "No more than 4% of students are offered
            'standard merit' places at Selective Entry High Schools from a
            source school (excluding P–9 schools). The quota that applies to a
            P–9 school is up to 10%. Quotas apply to minimise the impact on
            source schools."
          </p>
          <div className="text-center mt-16">
            <a href="/buy-exams">
              <button
                type="button"
                className="transition-scale duration-500 hover:scale-90 text-center rounded-md bg-indigo-800 px-16 py-4 text-xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Buy Now
              </button>
            </a>
          </div>
        </div>

        <div className="py-6 md:py-12 mt-10">
          <h3
            className="lg:w-8/12 mx-auto text-xl md:text-4xl font-bold text-center md:leading-10"
          >
            Our services maximise your chances of being selected to the
            following schools.
          </h3>
          <div className="flex flex-col lg:flex-row justify-between items-center py-10 mt-16">
            <img src={s2} className="w-56 mb-12" />
            <img src={s3} className="w-56 mb-12" />
            <img src={s4} className="w-56 mb-12" />
            <img src={s5} className="w-56 mb-12" />
          </div>
        </div>
        <div className="py-6 md:py-12">
          <h1
            className="md:text-4xl text-xl font-bold text-center lg:px-32 md:leading-10"
          >
            How can NRICH Learning assist the child with the Selective
            School Examination
          </h1>
          <div className="lg:flex justify-between items-center mt-20">
            <div className="lg:w-5/12 ">
              <img src={s9} className="items-center px-4" />
            </div>
            <div className="lg:w-7/12 lg:pl-16 px-4 md:px-8 w-full ">
              <h3 className=" text-2xl md:text-[28px] font-bold text-center md:text-start mt-4  ">
                Online and face-to-face tutoring.
              </h3>
              <br></br>
              <p className="md:text-xl text-base">
                NRICH Learning runs specialised classes for students preparing
                for Selective School Entrance Exams. <br></br>
                <br></br>
                We assist both the child and the parent in navigating the
                Private/Public School admissions process. Your child will be
                guided through the preparation process, with an emphasis on
                English comprehension and creative writing, mathematics, exam
                and interview techniques, and, most significantly, fun. A
                cheerful youngster is almost often a high achiever.
                <br></br>
                <br></br>
                NRICH Learning English program gives a substantial weekly
                practice of Reading Comprehension and writing exercises. Every
                alternate week, the program challenges students to write various
                essays (persuasive, narrative, descriptive and expository). The
                teachers target the essay structure and composition. They
                provide essential tips to improve the students’ skill level
                above and beyond the average school level. The students are also
                trained in solving Verbal reasoning problems.
                <br></br>
                <br></br>
                Our Mathematics program takes the students from the average
                skill level to an advanced level. The program prepares the
                students to solve complex word problems with ease. The content
                and approach of our program focus on the types of questions
                found in the actual examination your child will be sitting. This
                provides the child with an essential experience of the exam
                scenario. Numerical reasoning exercises are also an essential
                part of our Mathematics program.
                <br></br>
                <br></br>
                For details on our regular classes please visit{" "}
                <a
                  href="https://www.nrichlearning.com.au"
                  className="text-blue-600 underline"
                >
                  https://www.nrichlearning.com.au
                </a>{" "}
                The details about our Selective School curriculum can be found{" "}
                <a
                  href="https://www.nrichlearning.com.au/selective-and-scholarship-school-entry-exams/"
                  className="text-blue-600 underline"
                >
                  here.
                </a>
              </p>
            </div>
          </div>
          <div className="justify-between flex flex-col-reverse lg:flex-row items-center mt-20 mb-10">
            <div className="lg:w-7/12 lg:pr-16  px-4 mt-5 md:px-8">
              <h3 className="text-2xl md:text-[28px] font-bold text-center md:text-start  ">
                Online Mock exams Service (this portal)
              </h3>
              <br></br>
              <p className="md:text-xl text-base ">
                The online mock exams test the student's preparedness for the
                actual exam and is a paid subscription service. The
                student/parent can subscribe to one or more mock exams for a
                nominal fee. The student can appear in these mock practice
                exams, under exam conditions, with the added convenience of
                being accessible from the comfort of their own home. The student
                can access the exam anywhere during the subscription period and
                get much-needed practice. All exams provide the answers to the
                questions and justification for the correct answers.<br></br>
                <br></br> The students get a detailed report on their areas of
                strength and improvement opportunities. There is no limit on the
                number of times the student can reappear in these exams (while
                the subscription is valid). The parent/student can purchase one
                or more exams and avail a multi-buy discount.<br></br>
                <br></br> If there is one thing that is common and intrinsic to
                success, it is practice. Practice is the cornerstone of mastery
                and expertise in any field. Through consistent and deliberate
                practice, the students can refine their skills and improve their
                performance. Practice allows us to learn from our mistakes,
                identify areas for improvement, and develop the necessary muscle
                memory to execute tasks effortlessly. It builds resilience and
                fosters a growth mindset, enabling us to overcome challenges and
                push our limits. These mock exams provide the necessary revision
                and practice on the topics covered in the actual exam and are an
                excellent way to self-evaluate and test your preparedness for
                the Selective School Exams.<br /><br />
                Additionally, practice enhances confidence and
                self-assurance as we gain a deeper understanding of our
                abilities and witness tangible progress over time. Ultimately,
                whether in sports, academics, or any other pursuit, practice is
                the key that unlocks our full potential and paves the
                way for success.
              </p>
              <div className=" mt-10 text-center md:text-start md: mb-5">
                <a href="/buy-exams">
                  <button
                    type="button"
                    className="transition-scale duration-500 hover:scale-90  text-center rounded-md bg-indigo-800 px-16 py-4 text-xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Buy Now
                  </button>
                </a>
              </div>
            </div>
            <div className="lg:w-5/12 px-4">
              <img src={s10} className="" />
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default Aboutus;
