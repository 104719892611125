import Navbar from "./navbar";
import LandingPage from "./landingPage";
import PageFooter from "./pageFooter";
import AboutExam from "./about_us"

const home = () => {
  return (
    <>
      < AboutExam/>
    </>
  );
};

export default home;
