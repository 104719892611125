import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import EnrolmentPage from "./pages/enrolment";
import TermsAndConditions from "./pages/termsAndConditions";
import Successful from "./pages/successful";
import Cancel from "./pages/cancel";
import LandingPage from "./pages/landingPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/enrolment" element={<EnrolmentPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/buy-exams" element={<LandingPage />} />
      <Route path="/success" element={<Successful />} />
      <Route path="/failed" element={<Cancel />} />
    </Routes>
  );
}

export default App;
