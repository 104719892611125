import React from "react";
import { useState, useEffect, useRouter } from "react";
import PageFooter from "./pageFooter";
import { Link, useNavigate } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  TrashIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
// import { Link, useNavigate } from "react-router-dom";
import { ArrowRightIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";

import Logo from "../assets/images/logo.svg";

const TermsAndConditions = () => {
  const [isCartPage, setIsCartPage] = useState(false);
  const [cartData, setCartData] = useState([]);
  const navigate = useNavigate();
  return (
    <>
      <Disclosure
        as="nav"
        className="bg-white shadow sticky top-0 z-30 w-full px-1 sm:px-4"
      >
        {({ open }) => (
          <>
            <div className="">
              <div className="flex h-20 justify-between">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center">
                    <a href="https://www.nrichlearning.com.au/">
                      <img
                        className="block h-12 w-56 lg:hidden"
                        src={Logo}
                        alt="Your Company"
                      />
                    </a>
                    <a href="https://www.nrichlearning.com.au/">
                      <img
                        className="hidden h-12 w-56 lg:block"
                        src={Logo}
                        alt="Your Company"
                      />
                    </a>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8 e">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <a
                      onClick={() => navigate("/")}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                    >
                      About
                    </a>
                    <a
                      onClick={() => navigate("/buy-exams")}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                    >
                      Buy Exam
                    </a>

                    <a
                      onClick={() => navigate("/terms-and-conditions")}
                      className="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-indigo-500 border-indigo-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                    >
                      Terms and Conditions
                    </a>
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center"></div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon
                        className="block h-6 w-6 text-black"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pb-3 pt-2">
              <Disclosure.Button
                  as="a"
                  href="/buy-exams"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 "
                >
                  About
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/buy-exams"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 "
                >
                  Buy Exam
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/terms-and-conditions"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-indigo-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 "
                >
                  Terms and Conditions
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div
        className="flex flex-col items-center justify-center bg-white"
      >
        <div className="  ">
          <div
            className="h-40 flex justify-center items-center basis-1/2"
            style={{
              backgroundImage:
                " linear-gradient(90deg, rgba(55,48,163,1) 0%, rgba(67,56,202,0.5551470588235294) 53%, rgba(120,99,241,0.7176120448179272) 100%)",
              opacity: "1",
            }}
          >
            <h1 className="text-2xl font-bold text-center text-white align-middle md:text-4xl ">
              Terms and Conditions for NRICH Exam Platform
            </h1>
          </div>
          <div className="pt-10 mx-10 md:mx-40 text-justify gap-4">
            <p>
              Welcome to the NRICH Learning Selective School Entry Mock Exam
              Portal. Please read the following Terms and Conditions before you
              decide to buy a subscription to the mock exams.
            </p>
          </div>
          <div className="pt-10 mx-10 md:mx-40 text-justify gap-4">
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">Introduction</h2>
              <p className="mb-4 ">
                By using our website/portal and purchasing mock exams, you agree
                to these Terms and Conditions.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                PDF/Soft Copy of the exam
              </h2>
              <p className="mb-4">
                No PDF or soft copy of the exam will be provided. You can only
                access the exams through our online platform.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                Limited validity/subscription
              </h2>
              <p className="mb-2">
                Our online platform can only be accessed for a limited time,
                which is mentioned on the portal. Please read this information
                carefully before you make a purchase. All purchase is final and
                non-refundable. After you make the payment, you should receive
                an email which will provide the details of your purchase and the
                validity of the subscription. Please make sure you check your
                email in the spam folder, in case the email has ended up in the
                wrong place.{" "}
              </p>
              <p className="mb-2">
                Once the access period has expired, you will not be able to
                access the exams.
              </p>
              <p className="mb-2">
                Please refer to the following table for the cost and validity of
                the subscription.
              </p>

              <table className="mt-2 w-6/12 border-2 mb-4">
                <thead>
                  <tr className="border-b-2 text-center">
                    <th className="w-12">Number of Mock Exams subscribed</th>
                    <th className="w-12">Cost</th>
                    <th className="w-12">Validity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b-2 text-center">
                    <td>1</td>
                    <td>$10</td>
                    <td>10 days</td>
                  </tr>
                  <tr className="border-b-2 text-center">
                    <td>2</td>
                    <td>$20</td>
                    <td>10 days</td>
                  </tr>
                  <tr className="border-b-2 text-center">
                    <td>3</td>
                    <td>$25</td>
                    <td>15 days</td>
                  </tr>
                  <tr className="border-b-2 text-center">
                    <td>4</td>
                    <td>$40</td>
                    <td>15 days</td>
                  </tr>
                  <tr className="border-b-2 text-center">
                    <td>5</td>
                    <td>$50</td>
                    <td>15 days</td>
                  </tr>
                  <tr className="border-b-2 text-center">
                    <td>6</td>
                    <td>$55</td>
                    <td>20 days</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">Security</h2>
              <p className="mb-2">
                Please make sure that you never share your enrolment details
                with others. The system ensures that concurrent logins are not
                allowed.
              </p>
              <p className="mb-4">
                You’d also be requested to change your password when logging
                into the system for the first time. Please secure your password.
                You can always use the Forget Password functionality to reset
                and update your password.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                Unlimited attempts during the validity
              </h2>
              <p className="mb-4">
                You can attempt the exams as many times as you want within the
                validity period of your login. The detailed scores for each
                attempt will be recorded, and you can access them by logging in
                to your account.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">Intellectual Property</h2>
              <p className="mb-4">
                All content on our website, portal, including exams, questions,
                answers, and justifications, is protected by intellectual
                property rights. You may not use, copy, modify, or distribute
                any content from our website without our prior written consent.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                Accuracy of the answers
              </h2>
              <p className="mb-4">
                We have taken great care to ensure that the answers and
                justifications for the exams are correct, but errors might still
                be possible. We will make every effort to correct any errors
                brought to our attention, but we cannot be held financially
                liable for any such errors.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl  font-bold mb-2">Contact Us</h2>
              <p className="mb-4">
                If you have any questions or concerns about these Terms and
                Conditions, please contact us at{" "}
                <a
                  href="mailto:admin@nrichlearning.com.au"
                  className="underline underline-offset-1 decoration-indigo-500 text-indigo-500 "
                >
                  {" "}
                  admin@nrichlearning.com.au{" "}
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
};

export default TermsAndConditions;
