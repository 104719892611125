import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { openEnrollment } from "../schema/index";
import app_api from "../config/api";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { HomeIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import PageFooter from "./pageFooter";
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    parentFirstName: "",
    parentLastName: "",
    parentEmail: "",
    parentMobileNo: "",
    studentsFirstName: "",
    studentsLastName: "",
    terms: false,
  },
};
const EnrolmentPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [enrol, setEnrol] = useState([]);
  const [modal, setModal] = useState(initialModalState);
  const [batch, setBatch] = useState([]);
  const [open, setOpen] = useState(true);
  const [paymentData, setPaymentData] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [serviceParam, setServiceParam] = useState();
  const params = useParams();
  const URLlocation = useLocation();
  const [enrolmentPage, setEnrolmentPage] = useState([]);
  const [serviceID, setServiceID] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [bottom, setBottom] = useState(true);
  const [total, setTotal] = useState();

  var today = new Date().toISOString().split("T")[0];
  let jsonObj = [];
  let dataArr;
  useEffect(() => {
    dataArr = JSON.parse(localStorage.getItem("cart"));
    if (dataArr?.length == 3 || dataArr?.length == 6) {
      setDiscount(5);
    } else {
      setDiscount(0);
    }
    let total = dataArr?.reduce((s, { cost }) => s + cost, 0);
    setTotal(total);
    setEnrolmentPage(dataArr);
    jsonObj = dataArr?.map((l) => l.id);
    setServiceID(jsonObj);
    const queryParam = new URLSearchParams(URLlocation);
    const singleValue = queryParam.get("id");
    setServiceParam(singleValue);
  }, []);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;
    setBottom(!bottom);
  };

  return (
    <>
      <div className="flex min-h-screen infoPage">
        {!enrolmentPage && (
          <div className="absolute mainFormBody rounded-lg mx-5 mt-4 justify-center items-center sticky top-0 w-32 sm:w-32 md:w-32">
            <p className="text-black text-xl border-b border-gray-400 text-center mx-64">
              Order Summary
            </p>
            <div className="mt-5 flex flex-col items-center justify-center">
              <p className="font-mono text-gray-600 text-sm">
                Nothing added to the cart. To purchase an Exam, Click on the
                below button
              </p>
              <button
                className="w-48 px-2 py-1 rounded-lg bg-indigo-800 text-white mt-5 flex items-center justify-center"
                onClick={navigate("/")}
              >
                <HomeIcon className="w-4 h-4 text-white mr-2" />
                Home
              </button>
            </div>
          </div>
        )}
        {enrolmentPage && (
          <div className="absolute mainFormBody rounded-lg mx-5 px-8 mt-8 justify-center items-center sticky top-0 w-24 sm:w-32 md:w-24 lg:w-24 xl:w-24">
            <p className="text-black text-xl  border-b border-gray-400 text-center mx-auto max-w-max whitespace-nowrap font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
              Order Summary
            </p>
            <div className="flex flex-col justify-between w-full ml-4 mt-4  px-2 py-2">
              <p className="font-medium text-gray-500 font-medium text-base font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
                Pay NRICH Exam Platform
              </p>
              <p className="font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;] font-semibold text-4xl">
                {((total - discount + 0.3) / 0.9825)?.toFixed(2)}
              </p>
            </div>

            <div className="flex flex-col justify-between w-full ml-4 mt-4">
              <div
                className={
                  enrolmentPage?.length <= 3
                    ? "shadow-md rounded-lg bg-gray-50"
                    : "h-44 overflow-y-auto  shadow-md rounded-lg bg-gray-50"
                }
              >
                {enrolmentPage?.map((l) => (
                  <>
                    <div
                      className="flex flex-col items-center justify-between py-2"
                      key={l.id}
                    >
                      <div className="w-full px-2 py-2 flex justify-between">
                        <p className="py-2 text-md font-medium truncate font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
                          {l.name}
                        </p>
                        <p className="py-2 text-md font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;] mr-3">
                          {`$${l.cost}`}
                        </p>
                      </div>
                    </div>
                    <hr className="mx-2"></hr>
                  </>
                ))}
              </div>

              <div className="bg-gray-50 rounded-lg shadow border mt-8">
                <div className="flex justify-between px-2 text-sm text-gray-700 mt-2 mb-4">
                  <p className="text-base text-md mb-2 md:mb-0 md:pr-4 font-semibold font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
                    Subtotal:
                  </p>
                  <p className="text-base mb-2 md:mb-0 md:pr-4 font-medium font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
                    ${total?.toFixed(2)}
                  </p>
                </div>

                {discount > 0 && (
                  <div className="text-base mb-4 flex justify-between px-2 text-sm text-gray-500 pt-1 font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
                    <p className="text-base mb-2 md:mb-0 md:pr-4 font-semibold">
                      Discount:
                    </p>
                    <p className="text-red-500 text-base mb-2 md:mb-0 md:pr-4  font-medium font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
                      - ${discount?.toFixed(2)}
                    </p>
                  </div>
                )}

                <div className="flex justify-between px-2 text-sm text-gray-700 mt-2 mb-4">
                  <p className="text-base text-md mb-2 md:mb-0 md:pr-4 font-semibold font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
                    Processing fees:
                  </p>
                  <p className="text-base mb-2 md:mb-0 md:pr-4 font-medium font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
                    $
                    {(
                      (total - discount + 0.3) / 0.9825 -
                      (total - discount)
                    ).toFixed(2)}
                  </p>
                </div>

                <hr class="h-px my-2 mx-2 bg-black border-0" />
                <div className="flex justify-between px-2 text-sm text-gray-700 pt-1 mb-2 font-[-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;]">
                  <p className="text-base mb-2 md:mb-0 md:pr-4 font-semibold">
                    Total:
                  </p>
                  <p className="text-base mb-2 md:mb-0 md:pr-4  font-medium">
                    ${((total - discount + 0.3) / 0.9825)?.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="mt-4 text-gray-500 text-sm text-justify">
                <p>
                  Need help? contact us at{" "}
                  <span className="text-indigo-800">0434 678 639</span>
                  <br></br>
                  <br></br>
                  When your order is placed, we'll send you an e-mail message
                  acknowledging invoice of your order. You will be directed to
                  stripe to complete your payment. Your contract to purchase an
                  exam will not be complete until we receive your payment.
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="relative w-30 flex-1 lg:block mx-9 mt-2 mb-2">
          <Formik
            initialValues={initialModalState.data}
            validationSchema={openEnrollment}
            onSubmit={(values, { setSubmitting }) => {
              setLoading(true);
              app_api
                .post("/contact", {
                  parentEmail: values.parentEmail,
                  parentFirstName: values.parentFirstName,
                  parentLastName: values.parentLastName,
                  parentMobileNo: values.parentMobileNo.toString(),
                  studentsFirstName: values.studentsFirstName,
                  studentsLastName: values.studentsLastName,
                })
                .then((res) => {
                  app_api
                    .post("/enrolment", {
                      enrolment_type: "online",
                      serviceId: serviceID,
                      contactId: parseInt(res.data.id),
                    })
                    .then((res) => {
                      setLoading(false);
                      localStorage.removeItem("cart");
                      try {
                        if (res.status == 201) {
                          setPaymentData(res.data);
                          window.location.href = res.data.payment_link_url;
                        }
                      } catch (error) {
                        setSubmitting(false)
                        toast.error("Somethig Went Wrong");
                      }
                      setSubmitting(false);
                    })
                    .catch((err) => {
                      toast.error("Somethig Went Wrong");
                      setError(err.toString);
                      setLoading(false);
                      setSubmitting(false);
                    });
                })
                .catch((err) => {
                  if (err.response.data.statusCode == 424) {
                    toast.error("User with same student email already exists");
                  }
                  setLoading(false);
                  setSubmitting(false);
                  setError(
                    err?.response?.data?.message || "error creating contact"
                  );
                });
            }}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              setValues,
              values,
              errors,
              touched,
              isValid,
              setSubmitting,
              isSubmitting,
            }) => (
              <form
                className="w-full shadow-lg border rounded-lg mt-4 bg-white"
                onSubmit={handleSubmit}
                noValidate={true}
              >
                <div class="grid grid-cols-4 gap-4 flex flex-wrap">
                  <div class="col-span-4 h-4 mb-4">
                    <h2 className="text-2xl pt-2 pb-2 bg-indigo-800 text-white rounded-t-lg text-center">
                      Enrolment
                    </h2>
                  </div>
                  <div class="col-span-4 sm:col-span-2 mt-4 mr-4 ml-4 sm:ml-4">
                    <label htmlFor="" className="mb-1 text-sm text-gray-700">
                      Parent's First Name<span className="text-red-600">*</span>
                    </label>
                    <input
                      id="parentFirstName"
                      placeholder="Parent's First Name"
                      name="parentFirstName"
                      type="text"
                      autoComplete
                      required={true}
                      value={values.parentFirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.parentFirstName && (
                      <p className="text-red-700 error_msg">
                        {errors.parentFirstName}
                      </p>
                    )}
                  </div>
                  <div class="col-span-4 sm:col-span-2 mt-4 mr-4 ml-4">
                    <label htmlFor="" className="mb-2 text-sm text-gray-700">
                      Parent's Last Name<span className="text-red-600">*</span>
                    </label>
                    <input
                      id="parentLastName"
                      placeholder="Parent's Last Name"
                      name="parentLastName"
                      type="text"
                      autoComplete
                      required={true}
                      value={values.parentLastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.parentLastName && (
                      <p className="text-red-700 error_msg">
                        {errors.parentLastName}
                      </p>
                    )}
                  </div>
                  <div class="col-span-4 sm:col-span-2 mt-2 mr-4 ml-4">
                    <label htmlFor="" className="mb-2 text-sm text-gray-700">
                      Parent's Email<span className="text-red-600">*</span>
                    </label>
                    <input
                      id="parentEmail"
                      placeholder="Email"
                      name="parentEmail"
                      type="email"
                      autoComplete
                      required={true}
                      value={values.parentEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.parentEmail && (
                      <p className="text-red-700 error_msg">
                        {errors.parentEmail}
                      </p>
                    )}
                  </div>
                  <div class="col-span-4 sm:col-span-2 mt-2 mr-4 ml-4">
                    <label htmlFor="" className="mb-2 text-sm text-gray-700">
                      Parent's Mobile No<span className="text-red-600">*</span>
                    </label>
                    <PhoneInput
                      country={"au"}
                      enableAreaCodes={true}
                      autoFormat={true}
                      id="parentMobileNo"
                      placeholder="Mobile No"
                      name="parentMobileNo"
                      type="number"
                      className="block w-full appearance-none rounded-md placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      autoComplete
                      required={true}
                      value={values.parentMobileNo}
                      onChange={handleChange}
                      onBlur={(e) => {
                        setValues({
                          ...values,
                          parentMobileNo: e.target.defaultValue,
                        });
                      }}
                    />
                    {touched.parentMobileNo && (
                      <p className="text-red-700 error_msg">
                        {errors.parentMobileNo}
                      </p>
                    )}
                  </div>
                  <div class="col-span-4 sm:col-span-2 mt-2 mr-4 ml-4">
                    <label htmlFor="" className="mb-2 text-sm text-gray-700">
                      Student First Name
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="studentsFirstName"
                      placeholder="Student First Name"
                      name="studentsFirstName"
                      type="text"
                      autoComplete
                      required={true}
                      value={values.studentsFirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.studentsFirstName && (
                      <p className="text-red-700 error_msg">
                        {errors.studentsFirstName}
                      </p>
                    )}
                  </div>

                  <div class="col-span-4 sm:col-span-2 mt-2 mr-4 ml-4">
                    <label htmlFor="" className="mb-2 text-sm text-gray-700">
                      Student Last Name<span className="text-red-600">*</span>
                    </label>
                    <input
                      id="studentsLastName"
                      placeholder="Student Last Name"
                      name="studentsLastName"
                      type="text"
                      autoComplete
                      required={true}
                      value={values.studentsLastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.studentsLastName && (
                      <p className="text-red-700 error_msg">
                        {errors.studentsLastName}
                      </p>
                    )}
                  </div>
                  <hr className="col-span-4"></hr>
                  <div className="col-span-4 mx-4 flex justify-center items-center">
                    <p className="text-yellow-600 bg-yellow-100 px-3 py-1 rounded-2xl text-center text-sm">
                      Before purchasing the exams, we kindly ask that you take a
                      moment to review & accept the complete terms and
                      conditions
                    </p>
                  </div>
                  <div
                    className="col-span-4 mx-4 mt-1 h-48 overflow-y-auto shadow border rounded-lg"
                    onScroll={handleScroll}
                  >
                    <div className="">
                      <div className="flex justify-center items-center mt-5">
                        <p className="text-lg font-bold text-center text-black align-middle md:text-xl ">
                          Terms and Conditions for NRICH Exam Platform
                        </p>
                      </div>
                      <div className="pt-5 mx-5 text-justify">
                        <p>
                          Welcome to the NRICH Learning Selective School Entry
                          Mock Exam Portal. Please read the following Terms and
                          Conditions before you decide to buy a subscription to
                          the mock exams.
                        </p>
                      </div>
                      <div className="pt-10 mx-5 text-justify gap-4">
                        <div className="mb-8">
                          <h2 className="text-xl font-bold mb-2">
                            Introduction
                          </h2>
                          <p className="mb-4 ">
                            By using our website/portal and purchasing mock
                            exams, you agree to these Terms and Conditions.
                          </p>
                        </div>
                        <div className="mb-8">
                          <h2 className="text-xl font-bold mb-2">
                            PDF/Soft Copy of the exam
                          </h2>
                          <p className="mb-4">
                            No PDF or soft copy of the exam will be provided.
                            You can only access the exams through our online
                            platform.
                          </p>
                        </div>
                        <div className="mb-8">
                          <h2 className="text-xl font-bold mb-2">
                            Limited validity/subscription
                          </h2>
                          <p className="mb-2">
                            Our online platform can only be accessed for a
                            limited time, which is mentioned on the portal.
                            Please read this information carefully before you
                            make a purchase. All purchase is final and
                            non-refundable. After you make the payment, you
                            should receive an email which will provide the
                            details of your purchase and the validity of the
                            subscription. Please make sure you check your email
                            in the spam folder, in case the email has ended up
                            in the wrong place.{" "}
                          </p>
                          <p className="mb-2">
                            Once the access period has expired, you will not be
                            able to access the exams.
                          </p>
                          <p className="mb-2">
                            Please refer to the following table for the cost and
                            validity of the subscription.
                          </p>

                          <table className="mt-2 w-9/12 border-2 mb-4">
                            <thead>
                              <tr className="border-b-2 text-center">
                                <th className="w-16">
                                  Number of Mock Exams subscribed
                                </th>
                                <th className="w-16">Cost</th>
                                <th className="w-16">Validity</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="border-b-2 text-center">
                                <td>1</td>
                                <td>$10</td>
                                <td>10 days</td>
                              </tr>
                              <tr className="border-b-2 text-center">
                                <td>2</td>
                                <td>$20</td>
                                <td>10 days</td>
                              </tr>
                              <tr className="border-b-2 text-center">
                                <td>3</td>
                                <td>$25</td>
                                <td>15 days</td>
                              </tr>
                              <tr className="border-b-2 text-center">
                                <td>4</td>
                                <td>$40</td>
                                <td>15 days</td>
                              </tr>
                              <tr className="border-b-2 text-center">
                                <td>5</td>
                                <td>$50</td>
                                <td>15 days</td>
                              </tr>
                              <tr className="border-b-2 text-center">
                                <td>6</td>
                                <td>$55</td>
                                <td>20 days</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="mb-8">
                          <h2 className="text-xl font-bold mb-2">Security</h2>
                          <p className="mb-2">
                            Please make sure that you never share your enrolment
                            details with others. The system ensures that
                            concurrent logins are not allowed.
                          </p>
                          <p className="mb-4">
                            You’d also be requested to change your password when
                            logging into the system for the first time. Please
                            secure your password. You can always use the Forget
                            Password functionality to reset and update your
                            password.
                          </p>
                        </div>
                        <div className="mb-8">
                          <h2 className="text-xl font-bold mb-2">
                            Unlimited attempts during the validity
                          </h2>
                          <p className="mb-4">
                            You can attempt the exams as many times as you want
                            within the validity period of your login. The
                            detailed scores for each attempt will be recorded,
                            and you can access them by logging in to your
                            account.
                          </p>
                        </div>
                        <div className="mb-8">
                          <h2 className="text-xl font-bold mb-2">
                            Intellectual Property
                          </h2>
                          <p className="mb-4">
                            All content on our website, portal, including exams,
                            questions, answers, and justifications, is protected
                            by intellectual property rights. You may not use,
                            copy, modify, or distribute any content from our
                            website without our prior written consent.
                          </p>
                        </div>
                        <div className="mb-8">
                          <h2 className="text-xl font-bold mb-2">
                            Accuracy of the answers
                          </h2>
                          <p className="mb-4">
                            We have taken great care to ensure that the answers
                            and justifications for the exams are correct, but
                            errors might still be possible. We will make every
                            effort to correct any errors brought to our
                            attention, but we cannot be held financially liable
                            for any such errors.
                          </p>
                        </div>

                        <div className="mb-8">
                          <h2 className="text-xl  font-bold mb-2">
                            Contact Us
                          </h2>
                          <p className="mb-4">
                            If you have any questions or concerns about these
                            Terms and Conditions, please contact us at{" "}
                            <a
                              href="mailto:admin@nrichlearning.com.au"
                              className="underline underline-offset-1 decoration-indigo-500 text-indigo-500 "
                            >
                              {" "}
                              admin@nrichlearning.com.au{" "}
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-4 mr-4 ml-4">
                    <input
                      type="checkbox"
                      name="terms"
                      value={values.terms}
                      disabled={bottom}
                      className="w-3.5 h-3.5 mt-2"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          terms: e.target.checked,
                        });
                      }}
                    />
                    &nbsp;
                    <span className={bottom ? "text-gray-400" : "text-black"}>
                      {" "}
                      Please review and accept our terms and conditions to
                      proceed.
                    </span>
                    {touched.terms && (
                      <p className="text-red-700 error_msg">{errors.terms}</p>
                    )}
                  </div>
                </div>
                <div className="submitdiv">
                  <button
                    type="submit"
                    loading={loading}
                    // onClick={testFunc(values)}
                    disabled={isSubmitting}
                    className="submitBtn bg-green-700 text-white"
                  >
                    {loading ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="inline w-5 h-5 mr-2 text-white animate-spin fill-green-700 border-green-700"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default EnrolmentPage;
